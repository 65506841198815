import * as React from "react";

const SVGBBb = (props) => (
	<svg
		width={653}
		height={358}
		viewBox="0 0 653 358"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M313.287 69.9749C308.662 54.4293 298.983 40.8699 285.783 31.4426C270.155 20.8452 251.089 16.5703 232.433 19.4802L183.563 -14.7346C179.219 -17.9305 176.322 -22.7199 175.508 -28.0503C174.694 -33.3808 176.031 -38.8166 179.223 -43.1621L194.323 -64.724L159.46 -89.1321L144.36 -67.5702C134.33 -53.9124 130.132 -36.8314 132.69 -20.0802C135.247 -3.32883 144.351 11.7231 158 21.7687L183.376 39.5351C157.429 58.1981 138.582 85.1108 129.917 115.874L104.541 98.1076C90.4313 88.7187 73.1718 85.3133 56.554 88.6395C39.9363 91.9658 25.3193 101.752 15.9141 115.848L0.809445 137.417L35.6722 161.825L50.7718 140.263C53.7639 135.777 58.4153 132.662 63.7033 131.604C68.9913 130.545 74.4834 131.63 78.9726 134.618L127.842 168.833C131.488 187.357 142.027 203.809 157.331 214.868C170.704 224.044 186.755 228.498 202.944 227.526C133.283 329.448 154.767 466.094 251.613 533.898L286.483 558.311L339.959 481.95C357.817 456.551 370.443 427.853 377.102 397.527C381.481 401.666 386.153 405.482 391.085 408.943C450.137 450.287 533.138 433.706 576.432 371.883C619.726 310.06 606.957 226.43 547.868 185.061C542.93 181.61 537.748 178.521 532.363 175.82C558.586 159.191 581.237 137.513 598.999 112.047L652.475 35.6856L617.604 11.2721C520.879 -56.4693 385.299 -30.0498 313.287 69.9749ZM277.182 497.386C198.877 442.564 181.946 331.657 239.36 249.671L267.272 209.813C345.576 264.635 362.513 375.535 305.099 457.521L277.182 497.386ZM541.571 347.455C532.378 360.833 519.837 371.566 505.199 378.584C490.56 385.601 474.337 388.657 458.148 387.448L550.619 255.401C557.284 270.197 559.952 286.481 558.358 302.629C556.764 318.777 550.964 334.224 541.535 347.43L541.571 347.455ZM382.357 347.199C382.232 326.278 379.155 305.48 373.218 285.418L425.688 210.493C446.575 209.206 467.176 204.98 486.881 197.941L382.357 347.199ZM564.14 87.6181C506.725 169.604 396.702 191.629 318.398 136.806L346.31 96.9484C403.724 14.9625 513.747 -7.0614 592.051 47.7611L564.14 87.6181Z"
			fill="#F4DFA4"
		/>
	</svg>
);

export default SVGBBb;
