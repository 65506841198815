import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";
import SVGBBb from "./SVG/beebanner-bee";

const BeeBanner = ({ headline, btnTxt, btnLink, bgColor, text }) => {
	const ctaLink = btnLink || "/contact-us";

	return (
		<section
			style={{ overflow: "hidden" }}
			className={`bg-beeBanner py-7 py-md-6 py-lg-7 position-relative`}
		>
			<SVGBBb
				className="position-absolute d-none d-md-block top-0"
				style={{ right: "1.5rem" }}
			/>
			<Container>
				<Row
					style={{ zIndex: 2 }}
					className="position-relative align-items-lg-center"
				>
					<Col className="mb-4 mb-lg-0 text-center">
						<h2 className=" mb-4">{headline}</h2>
						<p className=" mb-4">{text}</p>

						<Button
							as={Link}
							style={{ zIndex: 2 }}
							variant={`secondary`}
							className="btn-black w-100 w-md-auto d-md-none fs-5 pb-2 position-relative cta-btn"
							href={ctaLink}
						>
							{btnTxt}
						</Button>
						<Button
							as={Link}
							style={{ zIndex: 2 }}
							variant={`secondary`}
							className="btn-black w-100 w-md-auto d-none d-md-inline-block fs-5 pb-2 position-relative cta-btn"
							to="/contact-us"
						>
							Contact Us
						</Button>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default BeeBanner;
