import React from "react";
import { CookieBanner } from "./cookie";
import Footer from "./footer";
import Navigation from "./navigation";

const Layout = ({ children, background }) => {
	return (
		<>
			<Navigation background={background} />
			<CookieBanner />
			{children}
			<Footer />
		</>
	);
};

export default Layout;
