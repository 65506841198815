import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ContactForm() {
	const [state, handleSubmit] = useForm("xeqnqbza");
	if (state.succeeded) {
		return (
			<div>
				<Form className=" text-center" onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="companyName">
						<Form.Control
							name="companyName"
							placeholder="Company name *"
							required
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="companyName"
						field="companyName"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="name">
						<Form.Control name="name" placeholder="Name" type="text" />
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />

					<Form.Group className="mb-3" controlId="telephone">
						<Form.Control
							type="tel"
							name="telephone"
							placeholder="Telephone number"
						/>
					</Form.Group>

					<ValidationError
						prefix="Telephone"
						field="telephone"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="email">
						<Form.Control
							name="_replyto"
							type="email"
							placeholder="Email address"
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />

					<Form.Group className="mb-3" controlId="message">
						<Form.Control
							name="message"
							placeholder="Message"
							as="textarea"
							rows={3}
						/>
					</Form.Group>

					<ValidationError
						prefix="Message"
						field="message"
						errors={state.errors}
					/>

					{/* <div
						class="g-recaptcha"
						data-sitekey="6LdW5LodAAAAAGe7lVE7V4ByJxGNQkA1WvvLyR0t"
					></div> */}
					<Button
						size="small"
						variant="secondary"
						className="btn btn-black mx-auto mt-3 w-100 w-md-auto"
						type="submit"
						disabled={state.submitting}
					>
						Send
					</Button>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className=" text-center" onSubmit={handleSubmit}>
			<Form.Group className="mb-3" controlId="companyName">
				<Form.Control
					name="Company name"
					placeholder="Company name *"
					required
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="companyName"
				field="companyName"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="name">
				<Form.Control name="Name" placeholder="Name" type="text" />
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />

			<Form.Group className="mb-3" controlId="telephone">
				<Form.Control
					type="tel"
					name="Telephone number"
					placeholder="Telephone number"
				/>
			</Form.Group>

			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="email">
				<Form.Control
					name="Email address"
					type="email"
					placeholder="Email address"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />

			<Form.Group className="mb-3" controlId="message">
				<Form.Control
					name="Message"
					placeholder="Message"
					as="textarea"
					rows={3}
				/>
			</Form.Group>

			<ValidationError prefix="Message" field="message" errors={state.errors} />

			{/* <div
				class="g-recaptcha"
				data-sitekey="6LdW5LodAAAAAGe7lVE7V4ByJxGNQkA1WvvLyR0t"
			></div> */}
			<Button
				size="small"
				variant="secondary"
				className="btn btn-black mx-auto mt-3 w-100 w-md-auto"
				type="submit"
				disabled={state.submitting}
			>
				Send
			</Button>
		</Form>
	);
}
export default ContactForm;
